import axios from 'axios'
import { getJwt } from '../../../../redux/actions/authentication'
import { processError } from '../../../../utils/fetch-utils'
import { deleteNotificationRule, NotificationInitiators, NotificationRule, updateNotificationRuleAll } from '../../../../redux/reducers/notificationReducer'

export const NotificationRulesFetch = async (params?: any) => {
  params.setLoading(true)

  const url = new URL(`api/notification/rules`, window.location.origin)
  url.searchParams.set('gateway', params.Gateway)
  url.searchParams.set('page', params.Page)
  url.searchParams.set('count', params.Count)

  if (params.field?.length && params.by !== 'none') {
    url.searchParams.set('sort', params.field)
    url.searchParams.set('by', params.by)
  }

  try {
    const { data }: any = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    })

    params.dispatch(NotificationRule(data))
    params.setLoading(false)
  } catch (e) {
    processError(e, params.dispatch)
    params.setLoading(false)
  }
}

export const NotificationInitiatorsFetch = async (params?: any) => {
  const url = new URL(`api/notification/initiators`, window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  try {
    const { data }: any = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    })

    params.dispatch(NotificationInitiators(data))
  } catch (e) {
    processError(e, params.dispatch)
  }
}

export const deleteNotificationRuleFetch = async (params?: any) => {
  params.setLoading(true)

  const url = new URL(`api/notification/deleteRule`, window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  try {
    await axios.post(
      `${url}`,
      { Id: params.Id },
      {
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      },
    )
    params.dispatch(deleteNotificationRule({ Id: params.Id }))
    params.setLoading(false)
  } catch (e) {
    processError(e, params.dispatch)
    params.setLoading(false)
  }
}

export const updateNotificationRuleFetch = async (params?: any) => {
  const url = new URL(`api/notification/updateRule`, window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  try {
    await axios.post(
      `${url}`,
      { ...params.body },
      {
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      },
    )
  } catch (e) {
    processError(e, params.dispatch)
  }
}

export const addNotificationRuleFetch = async (params?: any) => {
  const url = new URL(`api/notification/addRule`, window.location.origin)
  url.searchParams.set('gateway', params.Gateway)

  try {
    await axios.post(
      `${url}`,
      { ...params.body },
      {
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      },
    )
  } catch (e) {
    processError(e, params.dispatch)
  }
}
