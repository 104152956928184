export enum Action {
  Authorized,
  Unauthorized,
  ShowModal,
  HideModal,
  ShowRightBar,
  HideRightBar,
  GotPositions,
  FlushPositions,
  GotPositionDetail,
  GotPositionsPools,
  GotDeals,
  GotReport,
  FlushDeals,
  GotPlatforms,
  GotMt4Sessions,
  GotMt4SymbolMap,
  GotPlatformSymbolMap,
  GotPlatformSessions,
  GotFixAccounts,
  GotAccountsProfile,
  GotFixSessions,
  GotFixMarginInfo,
  GotFixSwaps,
  GotFixSymbolMap,
  GotExtSymbolMap,
  GotLeverageProfile,
  AddLeverageRules,
  DeleteLeverageRules,
  AddLeverageProfile,
  DeleteLeverageProfile,
  ActiveLeverageRules,
  CloneLeverageRules,
  DefaultRules,
  ChangeLeverageRulesItemPriority,
  UpdateLeverageProfile,
  GotFeedAgregation,
  GotExecPreset,
  ChangeExecPreset,
  GotSysLpSymbolMapSynthetic,
  GotSysLpSymbolMapSyntheticIndex,
  ExecutionConfigurationInitialFind,
  FeedingConfigurationChangedTest2,
  SyntheticSymbolsPreviewDefault,
  SyntheticIndexPreviewDefault,
  DeleteExecPresetOne,
  GotFeedPool,
  GotExecPool,
  GotLP,
  GotPreset,
  GotFeedingPreset,
  ChangeFeedingPreset,
  GotExecutionLP,
  DeleteExecPreset,
  GotProfile,
  GotLPTypes,
  GotLPPool,
  GotLPPoolSynetic,
  GotExecPoolVolume,
  GotCountries,
  GotFeedPlatforms,
  GotFeedPlatformsOld,
  GotRoutingRules,
  GotProcessingRules,
  GotAggregationPools,
  GotAggregationPoolTypes,
  ChangeRoutingRule,
  ChangeProcessingRule,
  ChangeProcessingRuleSelect,
  DisableProcessingRule,
  EnableProcessingRule,
  RoutingRulesChangeOrder,
  DeleteRoutingRule,
  ToogleRoutingRule,
  AddRoutingRule,
  AddRoutingRuleCopy,
  ModifyVolumeTransformerCumulative,
  AddVolumeTransformerCumulative,
  DeleteVolumeTransformerCumulative,
  GotVolumeTransformerSymbolMap,
  LoadSpinner,
  ExecutionConfigurationInitial,
  ExecutionConfigurationChanged,
  ExecutionHighlightRelevant,
  FeedingConfigurationInitial,
  FeedingConfigurationChanged,
  FeedingConfigurationChangedTest,
  FeedingHighlightRelevant,
  DeleteProcessingRule,
  AddProcessingRule,
  GotProcessingRuleItems,
  GotBinanceSwapRuleItems,
  AddProcessingRuleItem,
  AddProcessingRuleItems,
  ChangeProcessingRuleItem,
  DeleteProcessingRuleItem,
  DeleteProcessingRuleItems,
  ChangeProcessingRuleItemPriority,
  DisableExecutionLp,
  EnableExecutionLp,
  GotExecutionLpBRules,
  AddExecutionLpBRule,
  ChangeExecutionLpBRule,
  DeleteExecutionLpBRule,
  DeleteExecutionLpBRules,
  ChangeExecutionLpBRulePriority,
  ChangePriorityExecPreset,
  GotGateways,
  AddFeedAggregation,
  ChangeFeedAggregation,
  DeleteFeedAggregation,
  AddFeedPlatform,
  ChangeFeedPlatform,
  DeleteFeedPlatform,
  ChangeFeedPlatformProfile,
  ChangeFeedPlatformPreset,
  AddAggregationPool,
  ChangeAggregationPool,
  ChangeAggregationPoolId,
  DeleteAggergationPool,
  AddExecutionLp,
  ChangeExecutionLp,
  DeleteExecutionLp,
  GotFeeding,
  AddFeedingLp,
  DeleteFeedPreset,
  DeleteFeedPresetOne,
  ChangeFeedingLp,
  DeleteFeedingLp,
  GotSummary,
  SummaryInitial,
  GotSummarySessions,
  GotExposure,
  ExposureInitial,
  GotExposureCurrencies,
  GotExposureLps,
  GotSysLps,
  GotSysLpSymbolMap,
  SyntheticIndexPreview,
  SyntheticSymbolsPreview,
  GotSysLpBinanceSelect,
  GotGlobalSymbolMap,
  ChangeGateway,
  GotBackupsActiveInitial,
  GotBackupsActive,
  GotBackupsRules,
  GotBackupHistory,
  GotBackupRules,
  GotCeActive,
  GotCeFailed,
  GotCeHistory,
  GotHedgedBbookHistory,
  GotHedgedBbookVolume,

  GotVolumeTransformerCumulative,
  HedgedConfig,

  GotCeRules,
  ChangeCeRulesOrder,
  GotConsolidationRules,
  GotConsolidationHistory,
  GotDropCopySessions,
  GotDropCopySettings,
  GotVolumeTransfer,
  GotGateway,
  GotDatabase,
  GotSmtp,
  AddFeedPlatformSymbol,
  replaceFeedPlatformSymbol,
  DeleteFeedPlatformSymbol,
  DeleteFeedPlatformSymbols,
  ChangeFeedPlatformSymbol,
  GotFeedPlatformSymbols,
  ChangePriorityFeedPlatformSymbols,
  GotStatus,
  FlushGateways,
  InProgressStart,
  InProgressEnd,
  GotUsers,
  GotQuotes,
  QuoteSourceSelected,
  QuoteChangeSelection,
  GotTradingQuotes,
  GotExecutionReport,
  BinanceFutures,
  AddBinanceFuturesSwap,
  DeleteBinanceFutures,
  ModifyBinanceFutures,
  DeleteBinanceFuturesOne,
  ChangePriorityBinanceFutures,
  ChangePriorityFeedPreset,
  ChangeRoutingRuleMove,
  ChangeProcessing,
  addNotificationChannel,
  NotificationChannel,
  deleteNotificationChannel,
  updateNotificationChannel,
  originNotificationChannel,
  NotificationReceiver,
  deleteNotificationReceiver,
  updateNotificationReceiver,
  NotificationRule,
  NotificationInitiators,
  deleteNotificationRule,
  originNotificationRule,
  addNotificationRuleReceiver,
  addNotificationRuleChannel,
  deleteNotificationRuleChannel,
  deleteNotificationRuleReceiver,
  updateNotificationRuleChannel,
  updateNotificationRuleReceiver,
  updateNotificationRuleAll,
  addNotificationRule,
  GotConnectorFilterPrices,
  updateFeedPlatformGap,
  GotReportRules,
  ClearReports,
  DeleteReportRule,
  ChatBotMessages,
  ChatBotReset,
  ChatBotSetMessage,
  SetSuggestedQuestions,
  SetFetchStatis,
  UpdateOpen,
}
