import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useQuery } from '../../../hooks/useQuery'
import { FeedExecPresetEntity } from '../../../entity/Preset-entitu'
import { Action } from '../../../redux/actions/actions'
import { throwErrorMessage, throwSuccessMessage } from '../../../utils/errors-utils'
import { showModal } from '../../../redux/actions/modal-actions'
import { ModalType } from '../../modals/modals'
import { FormattedMessage } from 'react-intl'
import { showRightBar } from '../../../redux/actions/rightbar-actions'
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd'
import {
  addAggregationPool,
  addProcessingRule,
  addRoutingRule,
  changeAggregationPoolImport,
  changeExecPreset,
  changeProcessingRuleImport,
  changeRoutingRuleImport,
  deleteExecPreset,
  deleteExecPresetOne,
  executionConfigurationChanged,
} from '../../../redux/actions/execution/execution-actions'
import SaveWarning from '../../SaveWarning'
import { AppBreadcrumb } from '@t4b/core'
import { breadcrumbItems } from '../../../utils/breadcrumb-gen'
import AnimatedContainer from '../../AnimatedContainer'
import SaveTable from '../../SaveTable'
import { faFileImport } from '@fortawesome/free-solid-svg-icons'
import AppButton from '../../AppButton'
import { downloadFileJson } from '../../../utils/fetch-utils'
import { buildTableHeadersConfigured } from '../../../utils/table-utils'
import { exportAggPool } from '../../../utils/aggregation-pool'

const TableHeader = [
  { name: 'Id', show: true, sortable: false },
  { name: 'Name', show: true, sortable: false },
  { name: 'Description', show: true, sortable: false },
  { name: 'Schedule', show: true, sortable: false },
  { name: 'Enabled', show: true, sortable: false },
  { name: 'Actions', show: true, sortable: false },
]

const ExecPreset = () => {
  const dispatch = useDispatch()
  const local = useLocation()
  const platformName = useQuery().get('platform')
  const { gateway } = useSelector((reduxState: any) => reduxState.gateways)
  const executionConfiguration = useSelector((state: any) => state.executionConfiguration)
  const [inputState, setInputState] = useState<any>({
    Gateway: '',
    Platform: '',
  })
  const [files, setFiles] = useState<any>({})
  const [didMount, setDidMount] = useState(false)
  const [stateDrop, setStateDrop] = useState('')

  useEffect(() => {
    if (!executionConfiguration.noChanges) {
      window.onunload = function () {
        return '...'
      }
      window.onbeforeunload = function () {
        return '...'
      }
    }
    return () => {
      window.onbeforeunload = function () {}
      window.onunload = function () {}
    }
  }, [executionConfiguration.noChanges]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.onkeydown = (event: any) => {
      if (event.code === 'F5') {
        event.preventDefault()
        dispatch(
          showModal('Feed', {
            headerText: 'feeding-configuration.header.f5',
            bodyText: () => <FormattedMessage id="feeding-configuration.body.f5" />,
            onYes: () => {
              return '/execution-configuration'
            },
          }),
        )
      }
    }
    return () => {
      window.onkeydown = null
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gateway.Name) {
      const newState = {
        Gateway: gateway.Name,
        Platform: platformName,
      }

      setInputState({
        ...inputState,
        ...newState,
      })
    }
  }, [gateway]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setDidMount(true), [])

  useEffect(() => {
    if (didMount) {
      if (files.ProcessingRules || files.AggregationPools || files.LpsA || files.RoutingRules || files.LpsB) {
        const aggregationPools = files.AggregationPools
        const lps = [...files.LpsA, ...files.LpsB]
        const processingRules = files.ProcessingRules
        const routingRules = files.RoutingRules

        dispatch({
          type: Action.GotExecutionLP,
          data: [...lps],
        })

        const t = routingRules.map((item: any) => {
          return {
            ...item,
            Id: item.Id - 1000,
            ProcessorId: item.ProcessorId - 1000,
          }
        })

        dispatch({
          type: Action.GotRoutingRules,
          data: [...t],
        })
        dispatch({
          type: Action.GotProcessingRules,
          data: [
            ...processingRules.map((item: any) => {
              return {
                ...item,
                Id: item.Id - 1000,
                Rules: item.Rules?.map((elem: any) => {
                  return {
                    AllowContinuousExecutionForFOK: elem.AllowContinuousExecutionForFOK,
                    DelayFrom: elem.DelayFrom,
                    DelayTo: elem.DelayTo,
                    Enabled: elem.Enabled,
                    ExecutionPriceSource: exportAggPool(elem.Mode),
                    Groups: elem.Groups,
                    Id: elem.Id,
                    LimitMarkupFrom: elem.LimitMarkupFrom,
                    LimitMarkupTo: elem.LimitMarkupTo,
                    Logins: elem.Logins,
                    MarkupFrom: elem.MarkupFrom,
                    MarkupTo: elem.MarkupTo,
                    MaxVolume: elem.MaxVolume,
                    MinVolume: elem.MinVolume,
                    OrderSides: elem.OrderSides,
                    OrderTypes: elem.OrderTypes,
                    RoundType: elem.RoundType,
                    Security: elem.Security,
                    Symbols: elem.Symbols,
                    ExecuteLimitsAsMarkets: elem?.ExecuteLimitsAsMarkets,
                  }
                }),
              }
            }),
          ],
        })

        dispatch({
          type: Action.GotAggregationPools,
          data: [...aggregationPools],
        })
        const ProfileId = executionConfiguration.Profile.map((el: any) => el.Id)
        files.Profile.forEach((el: any) => {
          if (ProfileId.includes(el.Id)) {
            return
          } else {
            el.Id = -Math.floor(Math.random() * (10000 - 1 + 1)) + 1
          }
        })
        dispatch({ type: Action.GotProfile, data: [...files.Profile] })
        dispatch(executionConfigurationChanged())
        throwSuccessMessage('Successfully')
      }
    }
  }, [dispatch, files, didMount]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (didMount) {
      if (files.processingRules || files.aggregationPools || files.lps || files.routingRules) {
        const aggregationPools = files.aggregationPools
        const lps = files.lps
        const processingRules = files.processingRules
        const routingRules = files.routingRules

        dispatch({
          type: Action.GotExecutionLP,
          data: lps,
        })
        const t = routingRules.map((item: any) => {
          return {
            ...item,
            Id: item.Id - 1000,
            ProcessorId: item.ProcessorId - 1000,
          }
        })

        dispatch({
          type: Action.GotRoutingRules,
          data: [...t],
        })
        dispatch({
          type: Action.GotProcessingRules,
          data: [
            ...processingRules.map((item: any) => {
              return {
                ...item,
                Id: item.Id - 1000,
                Rules: item.Rules?.map((elem: any) => {
                  return {
                    AllowContinuousExecutionForFOK: elem.AllowContinuousExecutionForFOK,
                    DelayFrom: elem.DelayFrom,
                    DelayTo: elem.DelayTo,
                    Enabled: elem.Enabled,
                    ExecutionPriceSource: exportAggPool(elem.Mode),
                    Groups: elem.Groups,
                    Id: elem.Id,
                    LimitMarkupFrom: elem.LimitMarkupFrom,
                    LimitMarkupTo: elem.LimitMarkupTo,
                    Logins: elem.Logins,
                    MarkupFrom: elem.MarkupFrom,
                    MarkupTo: elem.MarkupTo,
                    MaxVolume: elem.MaxVolume,
                    MinVolume: elem.MinVolume,
                    OrderSides: elem.OrderSides,
                    OrderTypes: elem.OrderTypes,
                    RoundType: elem.RoundType,
                    Security: elem.Security,
                    Symbols: elem.Symbols,
                    ExecuteLimitsAsMarkets: elem?.ExecuteLimitsAsMarkets,
                  }
                }),
              }
            }),
          ],
        })
        dispatch({
          type: Action.GotAggregationPools,
          data: [...aggregationPools],
        })
        const ProfileId = executionConfiguration.Profile.map((el: any) => el.Id)
        files.Profile.forEach((el: any) => {
          if (ProfileId.includes(el.Id)) {
            return
          } else {
            el.Id = -Math.floor(Math.random() * (10000 - 1 + 1)) + 1
          }
        })
        dispatch({ type: Action.GotProfile, data: [...files.Profile] })
        dispatch(executionConfigurationChanged())
        throwSuccessMessage('Successfully')
      }
    }
  }, [dispatch, files, didMount]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gateway.Name) {
      const newState = {
        Gateway: gateway.Name,
      }

      setInputState({
        ...inputState,
        ...newState,
      })
    }
  }, [gateway]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const profileName = executionConfiguration.Profile.map((item: any) => item.Name)
    const routing = funcSort(profileName, executionConfiguration.routingRules)
    const processing = funcSort(profileName, executionConfiguration.processingRules)

    dispatch({
      type: Action.GotRoutingRules,
      data: routing,
    })
    dispatch({
      type: Action.GotProcessingRules,
      data: processing,
    })
  }, [stateDrop]) // eslint-disable-line react-hooks/exhaustive-deps

  function funcSort(profileName: any, arr: any) {
    const newArr = []
    for (let i = 0; i < profileName.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        if (profileName[i] === arr[j].Profile) {
          newArr.push(arr[j])
        }
      }
    }
    return newArr
  }

  const handleDelete = (item: any) => {
    const activeRoutingRules = executionConfiguration?.routingRules?.filter((elm: any) => elm?.Profile === item?.Name).map((item: any) => item?.Name)
    const activeProcessingRules = executionConfiguration?.processingRules?.filter((elm: any) => elm?.Profile === item?.Name).map((item: any) => item?.Name)

    if (activeRoutingRules.length || activeProcessingRules.length) {
      dispatch(
        showModal(ModalType.General, {
          headerText: 'preset.modal.header',
          bodyText: () => (
            <div>
              <div>
                <FormattedMessage id="preset.modal.body.execOne" values={{ Name: item.Name }} />
              </div>
              <div className="mt-2">
                <FormattedMessage id="preset.modal.body.execTwo" values={{ Name: item.Name }} />
              </div>
            </div>
          ),
          onYes: () => {
            dispatch(deleteExecPresetOne(item))
            dispatch(executionConfigurationChanged())
          },
        }),
      )
    } else {
      dispatch(
        showModal(ModalType.General, {
          headerText: 'preset.modal.header',
          bodyText: () => <FormattedMessage id="preset.modal.body" values={{ Name: item.Name }} />,
          onYes: () => {
            dispatch(deleteExecPresetOne(item))
            dispatch(executionConfigurationChanged())
          },
        }),
      )
    }
  }

  const handleDeleteAll = () => {
    dispatch(
      showModal(ModalType.Confirm, {
        headerText: 'preset.modal.delete-all',
        bodyText: () => (
          <div>
            <div>
              <FormattedMessage id="bodyTextExecOne" />
            </div>
            <div>
              <b>WARNING:</b> <FormattedMessage id="bodyTextExecTwo" />
            </div>
            <div className="mb-2 mt-2">
              <FormattedMessage id="bodyTextConfirm" />
            </div>
          </div>
        ),
        onYes: () => {
          dispatch(deleteExecPreset())
          dispatch(executionConfigurationChanged())
        },
      }),
    )
  }

  const handleClone = (item: any) => {
    dispatch(
      showRightBar('ExecPreset', {
        type: 'clone',
        params: {
          ...inputState,
        },
        item: new FeedExecPresetEntity({ ...item, Name: '', PresetId: Math.floor(Math.random() * (1000 + 1 - 1)) }),
        prevPresetName: item.Name,
      }),
    )
  }

  const handleClick = (item: any) => {
    dispatch(
      showRightBar('ExecPreset', {
        type: 'modify',
        params: {
          ...inputState,
        },
        item: new FeedExecPresetEntity(item),
        prevPresetName: item.Name,
      }),
    )
  }

  const handleAdd = () => {
    dispatch(
      showRightBar('ExecPreset', {
        type: 'add',
        params: {
          ...inputState,
        },
        item: new FeedExecPresetEntity({}),
      }),
    )
  }

  const importPresetAll = (e: any | null) => {
    const fileReader = new FileReader()
    fileReader.readAsText(e.target.files[0], 'UTF-8')
    fileReader.onload = (e: any | null) => {
      try {
        const file = JSON.parse(e.target.result)
        if (file.version !== 4) {
          throwErrorMessage('FileVersionError')
          return
        } else {
          setFiles(file)
        }
      } catch (e) {
        throwErrorMessage('FileReadError')
      }
    }
  }

  const [onePreset, setOnePreset] = useState({ lps: [], routingRules: [], Profile: [], aggregationPools: [], processingRules: [] }) // eslint-disable-line react-hooks/exhaustive-deps

  const importPresetOne = (e: any | null) => {
    const fileReader = new FileReader()
    fileReader.readAsText(e.target.files[0], 'UTF-8')
    fileReader.onload = (e: any | null) => {
      try {
        const file = JSON.parse(e.target.result)
        if (file.version !== 4) {
          throwErrorMessage('FileVersionError')
          return
        } else {
          setOnePreset(file)
        }
      } catch (e) {
        throwErrorMessage('FileReadError')
      }
    }
  }

  useEffect(() => {
    if (onePreset?.Profile?.length) {
      const feedPlatformsId = executionConfiguration.processingRules.map((item: any) => item.Id)
      const routingRulesId = executionConfiguration.routingRules.map((item: any) => item.Id)
      onePreset?.routingRules?.forEach((item: any) => {
        if (routingRulesId?.includes(item.Id)) {
          dispatch(changeRoutingRuleImport(item))
        } else {
          if (feedPlatformsId.includes(item.ProcessorId)) {
            dispatch(addRoutingRule(item, true))
          } else {
            dispatch(addRoutingRule(item))
          }
        }
        dispatch(executionConfigurationChanged())
      })

      onePreset?.processingRules?.forEach((item: any) => {
        item = {
          ...item,
          Rules: item.Rules?.map((elem: any) => {
            return {
              AllowContinuousExecutionForFOK: elem.AllowContinuousExecutionForFOK,
              DelayFrom: elem.DelayFrom,
              DelayTo: elem.DelayTo,
              Enabled: elem.Enabled,
              ExecutionPriceSource: exportAggPool(elem.Mode),
              Groups: elem.Groups,
              Id: elem.Id,
              LimitMarkupFrom: elem.LimitMarkupFrom,
              LimitMarkupTo: elem.LimitMarkupTo,
              Logins: elem.Logins,
              MarkupFrom: elem.MarkupFrom,
              MarkupTo: elem.MarkupTo,
              MaxVolume: elem.MaxVolume,
              MinVolume: elem.MinVolume,
              OrderSides: elem.OrderSides,
              OrderTypes: elem.OrderTypes,
              RoundType: elem.RoundType,
              Security: elem.Security,
              Symbols: elem.Symbols,
              ExecuteLimitsAsMarkets: elem?.ExecuteLimitsAsMarkets,
            }
          }),
        }
        if (feedPlatformsId?.includes(item.Id)) {
          dispatch(changeProcessingRuleImport(item))
        } else {
          dispatch(addProcessingRule(item))
        }
        dispatch(executionConfigurationChanged())
      })

      const AggregatedId = executionConfiguration.aggregationPools.map((item: any) => item.Name)
      onePreset?.aggregationPools?.forEach((item: any) => {
        if (AggregatedId?.includes(item.Name)) {
          dispatch(changeAggregationPoolImport(item))
        } else {
          dispatch(addAggregationPool(item))
        }
        dispatch(executionConfigurationChanged())
      })

      const PresetId = executionConfiguration.Profile?.map((item: any) => item.Id)
      onePreset?.Profile.forEach((item: any) => {
        if (PresetId.includes(item.Id)) {
          dispatch(changeExecPreset(item))
        } else {
          dispatch({
            type: Action.GotExecPreset,
            data: new FeedExecPresetEntity({ ...item, Id: -1 * Math.floor(Math.random() * (1000 + 1 - 1)) }),
          })
        }
        dispatch(executionConfigurationChanged())
      })

      dispatch({
        type: Action.GotExecutionLP,
        data: onePreset?.lps,
      })
      throwSuccessMessage('Successfully')
    }
  }, [onePreset]) // eslint-disable-line react-hooks/exhaustive-deps

  const saveOptions = [
    {
      translateKey: 'import-page-one',
      callback: () => {},
      onChange: importPresetOne,
      flag: true,
    },
    {
      translateKey: 'import-page-all',
      callback: () => {},
      onChange: importPresetAll,
      flag: true,
    },
  ]

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    setStateDrop(result.draggableId)

    if (result.destination.index === result.source.index) {
      return
    }
    dispatch({
      type: Action.ChangePriorityExecPreset,
      data: { result },
    })
    dispatch(executionConfigurationChanged())
  }

  const ImportJSON = async (item: any) => {
    const exportOnePreset = {
      routingRules: executionConfiguration.routingRules.filter((el: any) => el.Profile === item.Name),
      processingRules: executionConfiguration.processingRules
        .filter((el: any) => el.Profile === item.Name)
        .map((item: any) => {
          return {
            ...item,
            Rules: item.Rules?.map((elem: any) => {
              return {
                AllowContinuousExecutionForFOK: elem.AllowContinuousExecutionForFOK,
                DelayFrom: elem.DelayFrom,
                DelayTo: elem.DelayTo,
                Enabled: elem.Enabled,
                Mode: exportAggPool(elem.ExecutionPriceSource),
                Groups: elem.Groups,
                Id: elem.Id,
                LimitMarkupFrom: elem.LimitMarkupFrom,
                LimitMarkupTo: elem.LimitMarkupTo,
                Logins: elem.Logins,
                MarkupFrom: elem.MarkupFrom,
                MarkupTo: elem.MarkupTo,
                MaxVolume: elem.MaxVolume,
                MinVolume: elem.MinVolume,
                OrderSides: elem.OrderSides,
                OrderTypes: elem.OrderTypes,
                RoundType: elem.RoundType,
                Security: elem.Security,
                Symbols: elem.Symbols,
                ExecuteLimitsAsMarkets: elem?.ExecuteLimitsAsMarkets,
              }
            }),
          }
        }),
      aggregationPools: executionConfiguration.aggregationPools.filter((el: any) => el.bunchProfiles.includes(item.Name)),
      Profile: executionConfiguration.Profile.filter((el: any) => el.Name === item.Name),
      lps: executionConfiguration.lps,
      version: 4,
    }
    const jsonObj = JSON.stringify(exportOnePreset, null, 2)
    await downloadFileJson('ExecutionConfiguration', jsonObj)
  }

  const profArr: any = {}
  executionConfiguration.Profile.map((prof: any) => {
    executionConfiguration.routingRules.map((item: any) => {
      if (prof.Name === item.Profile) {
        if (profArr[prof.Name]) {
          let flag = null
          if (item.Schedule.ScheduleType === 'Periodic') {
            flag =
              JSON.stringify({
                ScheduleType: JSON.parse(profArr[prof.Name]).ScheduleType,
                Start: JSON.parse(profArr[prof.Name]).Start,
                Duration: JSON.parse(profArr[prof.Name]).Duration,
                Days: JSON.parse(profArr[prof.Name]).Days?.map((el: any) => el.value),
              }) !==
              JSON.stringify({
                ScheduleType: item.Schedule.ScheduleType,
                Start: item.Schedule.Start,
                Duration: item.Schedule.Duration,
                Days: item.Schedule.Days.map((el: any) => el.value),
              })
          } else if (item.Schedule.ScheduleType === 'Range') {
            flag =
              JSON.stringify({
                ScheduleType: JSON.parse(profArr[prof.Name]).ScheduleType,
                Begin: JSON.parse(profArr[prof.Name]).Begin,
                End: JSON.parse(profArr[prof.Name]).End,
                Days: JSON.parse(profArr[prof.Name]).Days?.map((el: any) => el.value),
              }) !==
              JSON.stringify({
                ScheduleType: item.Schedule.ScheduleType,
                Begin: item.Schedule.Begin,
                End: item.Schedule.End,
                Days: item.Schedule.Days?.map((el: any) => el.value),
              })
          } else {
            flag = JSON.stringify({ ...JSON.parse(profArr[prof.Name]).ScheduleType }) !== JSON.stringify({ ...item.Schedule.ScheduleType })
          }
          if (flag) {
            profArr[prof.Name] = 1
          }
        } else {
          profArr[prof.Name] = JSON.stringify(item.Schedule)
        }
      }
    })
  })

  const newProfile = executionConfiguration.Profile.map((item: any) => {
    return [profArr].map((el: any) => {
      if (el[item.Name] === 1) {
        return {
          ...item,
          flagSchedule: true,
          ScheduleType: 'Custom',
        }
      } else {
        let newShcedule: any = {}
        if (el[item.Name]) {
          newShcedule = JSON.parse(el[item.Name])
        }

        return {
          ...item,
          flagSchedule: true,
          ...newShcedule,
        }
      }
    })
  })

  const exportAll = {
    routingRules: executionConfiguration.routingRules,
    processingRules: executionConfiguration.processingRules?.map((item: any) => {
      return {
        ...item,
        Rules: item.Rules?.map((elem: any) => {
          return {
            AllowContinuousExecutionForFOK: elem.AllowContinuousExecutionForFOK,
            DelayFrom: elem.DelayFrom,
            DelayTo: elem.DelayTo,
            Enabled: elem.Enabled,
            Mode: exportAggPool(elem.ExecutionPriceSource),
            Groups: elem.Groups,
            Id: elem.Id,
            LimitMarkupFrom: elem.LimitMarkupFrom,
            LimitMarkupTo: elem.LimitMarkupTo,
            Logins: elem.Logins,
            MarkupFrom: elem.MarkupFrom,
            MarkupTo: elem.MarkupTo,
            MaxVolume: elem.MaxVolume,
            MinVolume: elem.MinVolume,
            OrderSides: elem.OrderSides,
            OrderTypes: elem.OrderTypes,
            RoundType: elem.RoundType,
            Security: elem.Security,
            Symbols: elem.Symbols,
            ExecuteLimitsAsMarkets: elem.ExecuteLimitsAsMarkets,
          }
        }),
      }
    }),
    aggregationPools: executionConfiguration.aggregationPools,
    lps: executionConfiguration.lps,
    Profile: executionConfiguration.Profile,
    version: 4,
  }

  return (
    <>
      <div className="d-flex">
        <h2>
          <FormattedMessage id="exec-configuration.title.preset" />
        </h2>
        <SaveWarning noChanges={executionConfiguration.noChanges} link="/execution-configuration" />
      </div>
      <AppBreadcrumb items={breadcrumbItems(local.pathname)} />
      <AnimatedContainer loading={false}>
        <div>
          <div className="row">
            <div className="col-12 d-flex justify-content-end ">
              <AppButton variant="export-all" onClick={() => downloadFileJson('ExecutionConfiguration', JSON.stringify(exportAll, null, 2))} className="mr-2 mb-2" />
              <SaveTable key="save-table-options" text={'Import'} icon={faFileImport} items={saveOptions} />
              <AppButton variant="delete-all" onClick={handleDeleteAll} className="mr-2 mb-2" />
              <AppButton variant="add" onClick={handleAdd} className="mr-2 mb-2" />
            </div>
            <div className="col-12">
              <DragDropContext onDragEnd={onDragEnd}>
                <table className="table app-table table-hover" style={{ tableLayout: 'fixed' }}>
                  <thead>
                    <tr style={{ color: '#8dabc4' }}>{buildTableHeadersConfigured(TableHeader, { field: '', by: '' }, () => {}, 'preset')}</tr>
                  </thead>
                  <Droppable droppableId="table">
                    {(droppableProvided: DroppableProvided) => (
                      <tbody ref={ref => droppableProvided.innerRef(ref)} {...droppableProvided.droppableProps}>
                        {newProfile.flat(Infinity).map((item: any, index: number) => {
                          const onClone = () => handleClone(item)
                          const onDelete = () => handleDelete(item)
                          const onModify = () => handleClick(item)
                          return (
                            <Draggable draggableId={(item.PresetId + index).toString()} index={index} key={(item.PresetId + index).toString()}>
                              {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                <tr
                                  onClick={onModify}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={
                                    snapshot.isDragging
                                      ? {
                                          display: 'table',
                                          tableLayout: 'fixed',
                                          ...provided.draggableProps.style,
                                        }
                                      : {
                                          display: 'table-row',
                                          ...provided.draggableProps.style,
                                        }
                                  }
                                >
                                  <td>{Math.abs(item.Id)}</td>
                                  <td style={{ width: 200, wordBreak: 'break-word' }}>{item.Name}</td>
                                  <td style={{ width: 200, wordBreak: 'break-word' }}>{item.Description}</td>
                                  <td>{item.ScheduleType}</td>
                                  <td>
                                    <input type="checkbox" checked={item.Enabled} />
                                  </td>
                                  <td onClick={(event: any) => event.stopPropagation()} className="d-flex justify-content-center">
                                    <AppButton variant="export-all-tabled" onClick={() => ImportJSON(item)} className="mb-2 mr-2 " />
                                    <AppButton variant="clone" onClick={onClone} className="mb-2 mr-2 " />
                                    <AppButton variant="delete" onClick={onDelete} className="mb-2 mr-2 " />
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          )
                        })}
                        {executionConfiguration.Profile && executionConfiguration.Profile.length === 0 ? (
                          <tr>
                            <td className="text-center" colSpan={6}>
                              <FormattedMessage id="table.nodata" />
                            </td>
                          </tr>
                        ) : null}
                        {droppableProvided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            </div>
          </div>
        </div>
      </AnimatedContainer>
    </>
  )
}

export default ExecPreset
