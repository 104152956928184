import React, { FC, memo, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faChartPie, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { buildControlsExtTwoPerLine, sselectInput, textInput } from '../../../utils/controls'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { AppSwitch } from '@t4b/core'
import TextInput from '../../inputs/TextInput'
import { useSelector } from 'react-redux'
import { buildMultiselectOptionsFromArray } from '../../../utils/multiselect-utils'
import { Accordion, Card } from 'react-bootstrap'
import * as yup from 'yup'
import { transformEmptyStringMaxLength } from '../../../utils/schema-utils'

export const SyntheticIndexChildrenLpBar: FC<any> = memo(({ item, originItem, symbol, data, index, setStateIndex, setStateValid, type }) => {
  const { LPPoolSynetic } = useSelector((state: any) => state.sysLps)
  const symbols = useSelector((state: any) => state.globalSymbolMap)
  const [flag, setFlag] = useState(item.IsInvalidation)

  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(
    {
      ...item,
      LastSaveAsk: item.LastSaveAsk === 'null' ? '' : String(item.LastSaveAsk),
      LastSaveBid: item.LastSaveBid === 'null' ? '' : String(item.LastSaveBid),
      FeedAggregationName: item.FeedAggregationName,
      InvalidationTimeout: item.InvalidationTimeout === '0' || item.InvalidationTimeout === 0 ? '' : item.InvalidationTimeout,
    },
    {
      LastSaveAsk: yup
        .string()
        .matches(/^[0-9.]+$/gi)
        .transform((_: any, val: any) => String(val) || null)
        .transform(transformEmptyStringMaxLength)
        .notRequired()
        .nullable(),

      LastSaveBid: yup
        .string()
        .matches(/^[0-9.]+$/gi)
        .transform((_: any, val: any) => String(val) || null)
        .transform(transformEmptyStringMaxLength)
        .notRequired()
        .nullable(),
      //============
      MarkupBid: yup
        .string()
        .matches(/^[-0-9]+$/gi)
        .transform((_: any, val: any) => String(val) || null)
        .transform(transformEmptyStringMaxLength)
        .nullable(true)
        .required(),
      MarkupAsk: yup
        .string()
        .matches(/^[-0-9]+$/gi)
        .transform((_: any, val: any) => String(val) || null)
        .transform(transformEmptyStringMaxLength)
        .nullable(true)
        .required(),
      //============
      FeedAggregationName: yup.string().required(),

      InvalidationTimeout: flag
        ? yup
            .string()
            .matches(/^[0-9]+$/gi)
            .transform((_: any, val: any) => String(val) || null)
            .transform(transformEmptyStringMaxLength)
            .test('Is positive?', 'ERROR: The number must be greater than 0!', value => value > 0)
            .nullable(true)
            .notRequired()
        : yup
            .string()
            .matches(/^[0-9]+$/gi)
            .transform((_: any, val: any) => String(val) || null)
            .transform(transformEmptyStringMaxLength)
            .nullable(true)
            .notRequired(),

      QuotesCoefficient: yup
        .string()
        .matches(/^[0-9.]+$/gi)
        .transform(transformEmptyStringMaxLength)
        .required(),
    },
  )

  const [hidden, setHidden] = useState(true)

  const newSymbol = symbols?.data?.map((item: any) => item.Symbol).filter((item: any) => item !== symbol?.value)
  const filterDelete = data?.map((item: any) => item.GlobalSymbolNameChildren?.value)
  const filterTypeDelete = newSymbol?.filter((item: any) => !filterDelete?.includes(item))
  const buildMultiselectFromArray = buildMultiselectOptionsFromArray(filterTypeDelete)

  const map = new Map()
  originItem?.Children?.forEach(({ LastSaveAsk, LastSaveBid, GlobalSymbolName }: any) => {
    map.set(GlobalSymbolName, { LastSaveAsk, LastSaveBid })
  })

  useEffect(() => {
    const newDigits = symbols.data.find((item: any) => item.Symbol === inputState.GlobalSymbolNameChildren?.value)

    setInputState((prev: any) => {
      return { ...prev, Digits: newDigits?.Digits }
    })
  }, [inputState.GlobalSymbolNameChildren, symbols.data]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   const prevBidAsk = map.get(inputState.GlobalSymbolNameChildren.value)
  //
  //   setInputState((prev: any) => {
  //     return {
  //       ...prev,
  //       LastSaveAsk: String(prevBidAsk?.LastSaveAsk) === 'undefined' || 'null' ? '' : String(prevBidAsk?.LastSaveAsk),
  //       LastSaveBid: String(prevBidAsk?.LastSaveBid) === 'undefined' || 'null' ? '' : String(prevBidAsk?.LastSaveBid),
  //     }
  //   })
  // }, [inputState.GlobalSymbolNameChildren.value])

  useEffect(() => {
    setInputState((prev: any) => {
      if (!inputState.FeedAggregationName) {
        return {
          ...prev,
          FeedAggregationName: { value: LPPoolSynetic[0] ?? '', label: LPPoolSynetic[0] ?? '' },
        }
      } else {
        return {
          ...prev,
          FeedAggregationName: {
            value: inputState.FeedAggregationName.value ?? inputState.FeedAggregationName,
            label: inputState.FeedAggregationName.value ?? inputState.FeedAggregationName,
          },
        }
      }
    })
  }, [inputState?.FeedAggregationName.value]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'add' || type === 'modify') {
      setInputState((prev: any) => {
        let globalSymbol = prev?.GlobalSymbolNameChildren ? prev?.GlobalSymbolNameChildren : buildMultiselectFromArray[0]
        const digits = symbols?.data?.find((item: any) => item.Symbol === globalSymbol.value)
        return {
          ...prev,
          Digits: digits?.Digits ?? '5',
          GlobalSymbolNameChildren: prev?.GlobalSymbolNameChildren ? prev?.GlobalSymbolNameChildren : buildMultiselectFromArray[0],
        }
      })
    }
  }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInputState((prev: any) => {
      return {
        ...prev,
        GlobalSymbolNameChildren: prev?.GlobalSymbolNameChildren?.value !== symbol.value ? prev?.GlobalSymbolNameChildren : buildMultiselectFromArray[0],
        QuotesCoefficient: prev.QuotesCoefficient ? prev.QuotesCoefficient : '0.1',
      }
    })
  }, [symbol, inputState.GlobalSymbolNameChildren]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStateIndex((prev: any) => {
      const newChildren = prev?.Children?.map((item: any) => {
        if (item.Id === inputState.Id) {
          item = inputState
          return item
        } else {
          return item
        }
      })

      return {
        ...prev,
        Children: newChildren,
      }
    })
  }, [inputState]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStateValid(isValid())
  }, [inputState]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSwitch = (checked: boolean) => {
    setFlag(checked)
    setInputState((prev: any) => {
      return {
        ...prev,
        IsInvalidation: checked,
      }
    })
  }

  const invalidation = (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className=" d-flex align-items-center mt-3 ml-1">
          <span className={`${inputState.IsInvalidation ? 'IsInvalidation' : 'lastQuote'} direction-color mr-2`}>Keep last quote </span>
          <AppSwitch checked={inputState.IsInvalidation} onChange={handleSwitch} />
          <span className={`${!inputState.IsInvalidation ? 'IsInvalidation' : 'lastQuote'} direction-color ml-2`}> Invalidate</span>
        </div>
        <div style={{ width: 321 }}>
          <TextInput
            state={inputState}
            setState={setInputState}
            name="InvalidationTimeout"
            label="SynteticSymbols.InvalidationTimeout"
            setTouched={setTouched}
            touched={touched}
            errors={errors}
            isDisabled={inputState.IsInvalidation === false}
          />
        </div>
      </div>
    </div>
  )

  const handelDelete = (e: any, item: any) => {
    e.stopPropagation()
    setStateIndex((prev: any) => {
      return {
        ...prev,
        Children: prev.Children.filter((elem: any) => elem.Id !== item.Id),
      }
    })
  }

  return (
    <Accordion defaultActiveKey={hidden ? 'accordion' : undefined} style={{ width: '100%' }}>
      <Card className={`accordion-custom border-none`} style={{ overflow: 'visible' }}>
        <Card.Header
          className="d-flex justify-content-between p-0 accordion-custom__toggle border-none wordBreak"
          style={{ width: '100%' }}
          onClick={() => setHidden(prev => !prev)}
        >
          <Accordion.Toggle eventKey="accordion" className={`cursor-pointer d-flex align-items-center border-none font-500 flex-grow-1 text-wrap accordion-header-custom`} as="div">
            <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
              <div style={{ width: '70%' }}>
                {index + 1}: {item?.GlobalSymbolNameChildren?.value ? item?.GlobalSymbolNameChildren?.value : item?.GlobalSymbolName?.value}
              </div>
              {inputState.QuotesCoefficient && (
                <div className="d-flex align-items-center ml-auto" style={{ marginRight: 20 }}>
                  <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                  {inputState.QuotesCoefficient}
                </div>
              )}
              <FontAwesomeIcon
                icon={faAngleRight}
                className={hidden ? (inputState.QuotesCoefficient ? 'icon ' : 'icon ml-auto') : inputState.QuotesCoefficient ? 'icon icon-rotate ' : 'ml-auto icon icon-rotate'}
              />
              <div className={'ml-4'} onClick={e => handelDelete(e, item)}>
                <FontAwesomeIcon icon={faTrashCan} />
              </div>
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="accordion">
          <Card.Body className="accordion-custom__collapse accordion-collapse">
            <div className="">
              {buildControlsExtTwoPerLine(
                [
                  sselectInput('FeedAggregationName', buildMultiselectOptionsFromArray(LPPoolSynetic)).optionZindex(false).errorMessage('Incorrect value'),
                  sselectInput('GlobalSymbolNameChildren', buildMultiselectFromArray),
                  textInput('MarkupBid'),
                  textInput('MarkupAsk'),
                  textInput('LastSaveBid'),
                  textInput('LastSaveAsk'),
                ],
                inputState,
                setInputState,
                'SynteticLpIndexBar',
                touched,
                setTouched,
                errors,
              )}
              {invalidation}
              <div className="mt-4" style={{ width: 321 }}>
                <TextInput
                  state={inputState}
                  setState={setInputState}
                  name="QuotesCoefficient"
                  label="SynteticSymbols.QuotesCoefficient"
                  setTouched={setTouched}
                  touched={touched}
                  errors={errors}
                />
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
})
